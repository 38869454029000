/* eslint-disable no-undef */
Vue.createApp({
    data() {
        return {
            loading: false,
            moreFilters: false,
            items: [],
            total: 0,
            // eslint-disable-next-line no-undef
            page: query.page || 1,
            limit: 16,
            totalPages: 1,
            artist: "",
            format: "",
            year: "",
            genre: "",
            style: "",
            sortOrder: "artists_sort-asc",
            sort: "artists_sort",
            order: "asc",
            itemId: null,
            showModalDelete: false,
            showModalShare: false,
            // eslint-disable-next-line no-undef
            shareLink: `/collection/${userId}`,
            // eslint-disable-next-line no-undef
            isPublicCollection,
            // eslint-disable-next-line no-undef
            userId,
            // eslint-disable-next-line no-undef
            vueType,
            // eslint-disable-next-line no-undef
            query,
        };
    },
    created() {
        this.fetch();

        window.addEventListener("keydown", this.keyDown);
    },
    destroyed() {
        window.removeEventListener("keydown", this.keyDown);
    },
    methods: {
        formatParams(param) {
            return param.replace("&", "%26").replace("+", "%2B");
        },
        fetch() {
            this.loading = true;
            this.total = 0;

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const entries = urlParams.entries();

            const sortOrder = {
                sort: "artists_sort",
                order: "asc",
            };

            // eslint-disable-next-line no-restricted-syntax
            for (const entry of entries) {
                const [key, value] = entry;
                switch (key) {
                    case "artists_sort":
                        this.artist = value;
                        break;
                    default:
                        if (["order", "sort"].indexOf(key) !== -1) {
                            sortOrder[key] = value;
                        }
                        this[key] = value;
                }
            }

            this.sortOrder = `${sortOrder.sort}-${sortOrder.order}`;

            let url = `/api/v1/${action}?page=${this.page}&sort=${this.sort}&order=${this.order}`;
            if (this.artist) {
                url += `&artist=${this.formatParams(this.artist)}`;
            }
            if (this.format) {
                url += `&format=${this.formatParams(this.format)}`;
            }
            if (this.year) {
                url += `&year=${this.year}`;
            }
            if (this.genre) {
                url += `&genre=${this.formatParams(this.genre)}`;
            }
            if (this.style) {
                url += `&style=${this.formatParams(this.style)}`;
            }
            // INFO: Cas d'une collection partagée
            if (this.vueType === "public" && this.userId) {
                url += `&userId=${this.userId}`;
            }

            axios
                .get(url)
                .then((response) => {
                    this.items = response.data.rows;
                    this.limit = response.data.limit;
                    this.total = response.data.count || 0;
                    this.totalPages =
                        parseInt(response.data.count / this.limit, 10) +
                        (response.data.count % this.limit > 0 ? 1 : 0);
                })
                .catch((err) => {
                    showToastr(
                        err.response?.data?.message ||
                            "Impossible de charger votre collection"
                    );
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        changeUrl() {
            let url = `?page=${this.page}&limit=${this.limit}&sort=${this.sort}&order=${this.order}`;
            if (this.artist) {
                url += `&artists_sort=${this.formatParams(this.artist)}`;
            }
            if (this.format) {
                url += `&format=${this.formatParams(this.format)}`;
            }
            if (this.year) {
                url += `&year=${this.year}`;
            }
            if (this.genre) {
                url += `&genre=${this.formatParams(this.genre)}`;
            }
            if (this.style) {
                url += `&style=${this.formatParams(this.style)}`;
            }

            window.location.href = url;
        },
        next(event) {
            event.preventDefault();

            this.page += 1;

            this.changeUrl();
        },
        previous(event) {
            event.preventDefault();

            this.page -= 1;

            this.changeUrl();
        },
        goTo(page) {
            this.page = page;

            this.changeUrl();
        },
        changeSort() {
            const [sort, order] = this.sortOrder.split("-");
            this.sort = sort;
            this.order = order;
            this.page = 1;

            this.changeUrl();
        },
        changeFilter() {
            this.page = 1;

            this.changeUrl();
        },
        showMoreFilters() {
            this.moreFilters = !this.moreFilters;
        },
        toggleModal() {
            this.showModalDelete = !this.showModalDelete;
        },
        toggleModalShare() {
            this.showModalShare = !this.showModalShare;
        },
        showConfirmDelete(itemId) {
            this.itemId = itemId;
            this.toggleModal();
        },
        deleteItem() {
            // eslint-disable-next-line no-undef
            if (vueType !== "private") {
                return false;
            }
            return axios
                .delete(`/api/v1/${action}/${this.itemId}`)
                .then(() => {
                    this.fetch();
                })
                .catch((err) => {
                    showToastr(
                        err.response?.data?.message ||
                            "Impossible de supprimer cet album"
                    );
                })
                .finally(() => {
                    this.toggleModal();
                });
        },
        shareCollection() {
            // eslint-disable-next-line no-undef
            if (vueType !== "private") {
                return false;
            }
            return axios
                .patch(`/api/v1/me`, {
                    isPublicCollection: !this.isPublicCollection,
                })
                .then((res) => {
                    this.isPublicCollection = res.data.isPublicCollection;

                    if (this.isPublicCollection) {
                        showToastr(
                            "Votre collection est désormais publique",
                            true
                        );
                    } else {
                        showToastr(
                            "Votre collection n'est plus partagée",
                            true
                        );
                    }
                })
                .catch((err) => {
                    showToastr(
                        err.response?.data?.message ||
                            "Impossible de supprimer cet album"
                    );
                })
                .finally(() => {
                    this.toggleModalShare();
                });
        },
        renderAlbumTitle(item) {
            let render = "";

            for (let i = 0; i < item.artists.length; i += 1) {
                const { name, join } = item.artists[i];
                render += `${name} ${join ? `${join} ` : ""}`;
            }

            render += `- ${item.title}`;

            return render;
        },
        keyDown(event) {
            const keycode = event.code;
            if (this.showModalDelete && keycode === "Escape") {
                event.preventDefault();
                this.showModalDelete = false;
            }
            if (this.showModalShare && keycode === "Escape") {
                event.preventDefault();
                this.showModalShare = false;
            }
        },
    },
}).mount("#collection");
